import logo from './elephant-fishing-logo.png';
import csv from './csv.svg';
import './App.css';
import {useDropzone} from 'react-dropzone';
import { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Icon, Progress } from 'semantic-ui-react'


function App() {

    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [resultFile, setResultFile] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [currentFileSize, setCurrentFileSize] = useState(0);

    const onDrop = useCallback(acceptedFiles => {
        setCurrentFileSize(acceptedFiles[0].size)
        setUploadInProgress(true)
        setResultFile('')
        console.log(acceptedFiles);
        const formData = new FormData();
        formData.append(
            "file",
            acceptedFiles[0],
            acceptedFiles[0].name
        );
        // Request made to the backend api
        // Send formData object
        axios.post("https://post-tracker.elephantfishing.com/api/upload", formData).then((response) => {
            setUploadProgress(0);
            setUploadInProgress(false);
            setResultFile(response.data['csv_file']);
            console.log(response.status, response.data);
        });

    }, []);

    useEffect(() => {
        if (uploadInProgress) {
            const totalDuration = currentFileSize / 200000; // Total duration of the progress bar in seconds
            const updateInterval = 1000; // Interval at which the progress bar updates in milliseconds

            const interval = setInterval(() => {
                setUploadProgress((prevProgress) => prevProgress + 100/totalDuration);
            }, updateInterval);

            // Clear the interval when the component is unmounted or when progress reaches 100
            return () => clearInterval(interval);
        }
    }, [uploadInProgress, uploadProgress, currentFileSize]);

    const {getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject} = useDropzone({
        // Disable click and keydown behavior
        onDrop,
        noClick: true,
        noKeyboard: true,
        multiple: false,
        accept: {
            'application/pdf': ['.pdf'],
        },
    });

    function getStyle() {
        return {
            ...getRootProps({className: 'dropzone'}),
            style: {
                ...(isFocused ? {backgroundColor: 'red'} : {}),
                ...(isDragAccept ? {backgroundColor: '#e0e1e2'} : {}),
                ...(isDragReject ? {backgroundColor: 'red'} : {})
                }
        }
    }

    return (
        <div className="App">
            <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <h1 className="header-line">Willkommen bei <span className="blue-header-part">Post-Tracking</span></h1>

            <span className="sub-header">VON ELEPHANTFISHING.COM</span>
            {!uploadInProgress &&
                <div {...getStyle()}>
                    <input {...getInputProps()} />

                    <p>Schritt 1: Datei per Drag & Drop hochladen</p>
                    <p>ODER</p>

                    <Button icon labelPosition='left' primary type="button" onClick={open}>
                        <Icon name='upload' />
                        Browse
                    </Button>
                </div>
            }

            <div className="result-area">
                {uploadInProgress &&
                    <Progress percent={uploadProgress} indicating />
                }
                {resultFile &&
                    <>
                        <p>Schritt 2: Laden Sie Ihre .csv-Datei herunter!</p>
                        <div className="download-area">
                            <img src={csv} className="csv" alt="csv" />

                            <a
                                href={`https://post-tracker.elephantfishing.com/api/${resultFile}`}
                                download="post-tracking.csv"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button icon labelPosition='left' primary>
                                    Download
                                    <Icon name='download' />
                                </Button>
                            </a>
                        </div>
                    </>
                }
            </div>
            </header>
        </div>
    );
}

export default App;
